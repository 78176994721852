'use strict';

import {ILogService, IRootScopeService, IScope, IWindowService} from "angular";
import RestService from "../../../../services/rest.service";

require('./admin.logs.component.css');

export default class AdminLogsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./admin.logs.component.html');
    this.scope = {
    };
    this.controller = AdminLogsComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}

/* @ngInject */
class AdminLogsComponentController {
  public $scope: any;
  public $rootScope: any;
  public dataService: any;
  public isLoading: boolean = false;
  public $log: ILogService;
  public restService: RestService;
  public logFiles: any;
  public logDirs: any;
  public currentDir: string;
  public isDateSearch = false;
  public jumpToDate: Date;
  public rootDir = "/";

  constructor($scope: IScope, $rootScope: IRootScopeService, dataService, $log: ILogService, restService: RestService, public $window: IWindowService,) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.dataService = dataService;
    this.$log = $log;
    this.restService = restService;
    this.loadLogFiles(this.rootDir);
    this.jumpToDate = null;
  };

  downloadLogFiles() {
    this.$window.open(
      `${this.restService.getLogZipPath(this.isDateSearch ? this.jumpToDate.getTime() : 0)}`,
      '_blank'
    );
  }

  downloadSingleLogFile(log:any){
    this.$window.open(`${this.restService.getLogPathDownload(this.currentDir,log)}`,
      '_blank'
    )
  }

  resetDateFilter() {
    this.jumpToDate = null;
    this.isDateSearch = false;
    this.refresh();
  }

  refresh() {
    this.loadLogFiles(this.rootDir);
  }

  searchForDate() {
    if (!this.jumpToDate) return;
    this.isDateSearch = true;
    this.loadLogFiles(this.rootDir);
  }

  loadLogFiles(currentDir: string) {
    this.isLoading = true;
    this.dataService.getLogsInFolder(
      currentDir,
      this.isDateSearch ? this.jumpToDate.getTime() : 0,
      (logs) => {
        this.$log.debug(logs);
        this.logFiles = logs.files;
        this.logDirs = logs.dirs;
        this.currentDir = logs.currentDir;
        this.isLoading = false;
      },
      (response) => {
        //Error occured
        this.isLoading = false;
        this.$log.error(response);
      });
  };


  getLogPath(log) {
    return this.restService.getLogPath(this.currentDir, log);
  };
}



