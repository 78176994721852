'use strict';
import {IScope, ITimeoutService} from "angular";
import {
  EMissionReportState,
  MissionReport,
  MissionReportPaticipantsRequest, MissionReportPersonRequest,
  ReportedMissionDataRequest
} from "../../../../../data/emergency.data";
import RestService from "../../../../../services/rest.service";
import {
  DataBasePersonForTracking,
  PaginatedDatabasePersonForTracking, PaginatedPersonSearchQueryResult, PersonsAddressbookResponse,
  PersonSearchQueryResult
} from "../../../../../data/person.data";
import PrivilegeService from "../../../../../services/privilege.service";
import {RolePrivilege} from "../../../../../data/privileges.enum";
import {SortParams} from "../../../../views/addressbook.view.component/addressbook.view.component";
import HelperService from "../../../../../services/helper.service";
/* @ngInject */
export default class MissionReportParticipantsComponent {
  public restrict: string;
  public scope;
  public template;
  public controller;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.scope = {
      report: "=",
      emergency: "="
    }
    this.template = require('./mission.report.participants.component.html');
    this.controller = MissionReportParticipantsController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}
require('./mission.report.participants.component.scss')

class MissionReportParticipantsController {

  public $scope: IScope;
  public report: MissionReport;
  public hasEditRight: boolean = false;
  public persons: PaginatedPersonSearchQueryResult;
  public isLoadingPersons: boolean;
  public showSelected: boolean = false;
  public showSelectedPersons: PersonSearchQueryResult[];
  public externalPerson: MissionReportPaticipantsRequest[] = [];
  public helper: HelperService;
  public listeners= [];

  public emergency;
  Emergency;
  public params = {
    sortType: 'displayName',
    searchFilter: '',
    currentPage: 0,
    pageSize: 10
  } as SortParams;


  constructor($scope: IScope, public $rootScope: any, public privilegeService: PrivilegeService, public restService: RestService, public $uibModal, public $timeout: ITimeoutService, helperService: HelperService) {
    this.$scope = $scope;
    this.showSelectedPersons = [];
    this.helper = helperService;


    this.listeners.push(this.$scope.$watch("ctrl.report", (newValue: ReportedMissionDataRequest, oldValue) => {
      if (this.report) {
        this.hasEditRight = this.privilegeService.has(RolePrivilege.Home_Emergency_Details_Report_Write);
        if (this.report.state === EMissionReportState.PUBLISHED) {
          this.hasEditRight = false;
        }
        this.isLoadingPersons = true;
        this.restService.loadPersonsForReport(this.params.currentPage === 0 ? 0 : this.params.currentPage - 1,
          this.params.searchFilter,
          this.params.sortReverse ? 'DESC' : 'ASC',
          this.params.pageSize,
          '',
          false, this.emergency.parentUserId).then((persons) => {
          this.persons = persons;
          this.updateUI();
        }).finally(() => {
          this.isLoadingPersons = false;
        })

        if (this.report.participants && this.report.participants.length > 0) {
          this.showSelectedPersons = [];
          this.externalPerson = [];
          this.report.participants.forEach((person) => {
            if (person.person.personId){
            let searchRep = {
              personID: person.person.personId,
              displayName: person.person.displayName,
              osId: person.person.osId
            } as PersonSearchQueryResult;
            this.showSelectedPersons.push(searchRep);
            }else {
              this.externalPerson.push(person);
            }
          })
        }
      }
    }));

    this.listeners.push($scope.$on('reportParticipant.add',  (event,newPerson: PersonSearchQueryResult) => {
      if (this.report) {
        this.showSelectedPersons.push(newPerson);
      }
    }));
    this.listeners.push($scope.$on('reportParticipant.remove', (event,oldPerson: PersonSearchQueryResult) => {
      if (this.report) {
        let index = this.showSelectedPersons.findIndex((person) => person.personID === oldPerson.personID);
        this.showSelectedPersons.splice(index,1);
      }
    }));
    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }



    removeExternalPerson(externalPerson: MissionReportPaticipantsRequest){
    var index = this.externalPerson.indexOf(externalPerson, 0);
    this.externalPerson.splice(index, 1);

    var reportIndex= this.report.participants.indexOf(externalPerson);
    this.report.participants.splice(reportIndex, 1);
  }
  checkExtern(externalPerson: MissionReportPaticipantsRequest){
    externalPerson.agt= !externalPerson.agt;
    if (!externalPerson.agt){
      externalPerson.agtTime= 0;
    }
  }

  getCorrectList(): PersonSearchQueryResult[] {
    // values are only set, if these are loaded
    if (this.report && this.persons) {
      if (this.showSelected) {
        return this.showSelectedPersons;
      }
      return this.persons.content;
    }
  }

  previousPage() {
    this.params.currentPage--;
    if (this.params.currentPage < 0) {
      this.params.currentPage = 0;
    }
    this.pageChanged(true).then(() => {
      this.$scope.$applyAsync();
    });

  }

  nextPage() {
    this.params.currentPage++;
    if (this.params.currentPage < 0) {
      this.params.currentPage = 0;
    }
    this.pageChanged(true).then(() => {
      this.$scope.$applyAsync();
    });
  }

  /**
   * page of table view changed (possibly many entries with addressbook entries
   * @param fromPageSelection
   */
  pageChanged(fromPageSelection?: boolean) {

    this.isLoadingPersons = true;
    return new Promise<void>((resolve, reject) => {

      let searchPage = this.params.currentPage === 0 ? 0 : this.params.currentPage;

      // Check filtering only if page selection did not change
      if (!fromPageSelection) {
        searchPage = 0;
        if (!this.params.searchFilter) {
          this.params.currentPage = 0;
        }

      }
      this.restService.loadPersonsForReport(searchPage,
        this.params.searchFilter,
        this.params.sortReverse ? 'DESC' : 'ASC',
        this.params.pageSize,
        undefined,
        false, this.emergency.parentUserId)
        .then((result: PaginatedPersonSearchQueryResult) => {
          this.persons = result;
          this.updateUI();
        }).finally(() => {
        this.isLoadingPersons = false;
        this.$scope.$applyAsync();
        var search = document.getElementById('report-person-search');
        if (search) {
          this.$timeout(function () {
            search.focus();
          });
        }
        resolve();
      });
    });
  }
  getDisplayPage(){
    return this.params.currentPage + 1;
  }

  updateUI() {
    if (!this.persons) {
      return;
    }
    this.params.currentPage = this.persons.number;
  };

  resetSearchAndReload() {
    this.params.searchFilter = '';
    this.params.currentPage = 0;
    this.pageChanged();
  };

}