'use strict';
import {IScope} from "angular";
import {
  Emergency,
  EOwnLeaderFunction,
  MissionReport,
  MissionReportPersonRequest,
  ReportedMissionDataRequest
} from "../../../../../data/emergency.data";
import RestService from "../../../../../services/rest.service";
import {PersonSearchQueryResult} from "../../../../../data/person.data";
import PrivilegeService from "../../../../../services/privilege.service";
import {RolePrivilege} from "../../../../../data/privileges.enum";
import HelperService from "../../../../../services/helper.service";
/* @ngInject */
export default class MissionReportMissionDataComponent {
  public restrict: string;
  public scope;
  public template;
  public controller;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.scope = {
      report: "=",
      emergency: "=",
      edit: "="
    }
    this.template = require('./mission.report.missionData.component.html');
    this.controller = MissionReportMissionDataController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}
require('./mission.report.missionData.component.scss')

class MissionReportMissionDataController {

  public $scope: IScope;
  public report: MissionReport;
  public reportMissionData: ReportedMissionDataRequest;
  public hasEditRight: boolean;
  public leaderFunctions: typeof EOwnLeaderFunction = EOwnLeaderFunction;

  public edit: boolean = true;
  public emergency: Emergency;
  public ownLeaderPerson: PersonSearchQueryResult;
  public leaderPerson: PersonSearchQueryResult;
  public helper;


  constructor($scope: IScope, public $rootScope: any, public privilegeService: PrivilegeService, public restService: RestService, public $uibModal, helperService:HelperService) {
    this.$scope = $scope;
    this.hasEditRight = this.privilegeService.has(RolePrivilege.Home_Emergency_Details_Report_Write);
    this.helper= helperService;


    this.$rootScope.$watch("ctrl.report", (newValue: ReportedMissionDataRequest, oldValue) => {
      if (this.report) {
        this.reportMissionData = this.report.missionData;
        if (this.reportMissionData) {
          if (this.reportMissionData.missionLeader) {
            this.leaderPerson = {
              personID: this.reportMissionData.missionLeader.personId,
              displayName: this.reportMissionData.missionLeader.displayName,
              osId: this.reportMissionData.missionLeader.osId
            } as PersonSearchQueryResult;
          }
          if (this.reportMissionData.ownMissionLeader) {
            this.ownLeaderPerson = {
              personID: this.reportMissionData.ownMissionLeader.personId,
              displayName: this.reportMissionData.ownMissionLeader.displayName,
              osId: this.reportMissionData.ownMissionLeader.osId

            } as PersonSearchQueryResult;
          }
        }
      }
    })
  }



  getPersons(val) {
    return this.restService.searchForPersons(val, this.emergency.parentUserId);
  }

  /**
   * selects the mission leader
   * @param person
   */
  leaderSelected(person) {
    this.reportMissionData.missionLeader = {
      personId: person.personID,
      displayName: person.displayName,
      osId: person.osId
    } as MissionReportPersonRequest;
    this.leaderPerson = person;
  }

  /**
   * undo value of mission leader, if name was removed
   */
  leaderChanged() {
    this.reportMissionData.missionLeader = {
      displayName: this.leaderPerson.displayName
    } as MissionReportPersonRequest
  }

  /**
   * selection of Own Mission leader
   * @param person
   */
  ownLeaderSelected(person) {
    this.reportMissionData.ownMissionLeader = {
      personId: person.personID,
      displayName: person.displayName,
      osId: person.osId
    } as MissionReportPersonRequest;
    this.ownLeaderPerson = person;
  }

  /**
   * undo value of own mission leader if name was removed
   */
  ownLeaderChanged() {
    this.reportMissionData.ownMissionLeader = {
      displayName: this.ownLeaderPerson.displayName
    } as MissionReportPersonRequest
  }

  /**
   * get set own mission leader function or default {@link EOwnLeaderFunction.GROUP_LEADER}
   */
  getFunction() {
    if (this.report.missionData.functionOwnMissionLeader) {
      return 'MISSIONS.MISSION_REPORT.MISSION_LEADER_FUNCTIONS.' +this.report.missionData.functionOwnMissionLeader
    } else {
      return "-";
    }
  }
}